<template>
  <cui-acl :roles="['CompanySettings']" redirect>
    <div :class="$style.cui__sidebar__inner">
      <h5>
        <strong>Integration Settings Skygruppen</strong>
      </h5>
      <div class="cui__utils__line" :style="{ marginTop: '25px', marginBottom: '30px' }"></div>
      <div :class="$style.cui__sidebar__type" class="mb-4">
        <h4>Microsoft</h4>
          <a-spin :spinning="loading" >
          <a-form @submit="updateIntegration">
            <a-form-item label="Tenant">
              <a-input placeholder="ID" v-model="data.tenantId"/>
            </a-form-item>
            <a-form-item label="ClientID">
              <a-input placeholder="ID" v-model="data.clientId"/>
            </a-form-item>
            <a-form-item label="ClientSecret">
              <a-input placeholder="ID" v-model="data.clientSecret"/>
            </a-form-item>
            <a-button>Update</a-button>
          </a-form>
          <a-divider></a-divider>
          <h5>Licenses</h5>
          <a-table bordered :data-source="data.licenses" :columns="licenseColumns">
            <template slot="name" slot-scope="text">
              {{ MSlicensesmap.find(el => el.String_Id === text ).Product_Display_Name }}
            </template>
            <template slot="availableUnits" slot-scope="record">
              {{ Number(record.prepaidUnits.enabled) - Number(record.consumedUnits) }}
            </template>
            <template slot="standardLicense" slot-scope="record">
              <a-switch v-model="record.standardLicense" @change="changeStandardLicense(record)" />
            </template>
            <template slot="editAmount" slot-scope="record">
              <a :href="'https://admin.microsoft.com/#/licensedetailpage/'+record.skuId" target="_blank">Endre</a>
            </template>
          </a-table>
          </a-spin>
          <a-divider></a-divider>
          <h5>Email Addresses</h5>
        </div>
      </div>
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import { firestore, functions } from '@/services/firebase'
const MSlicensesmap = require('./MSlicensesmap.json')

export default {
  components: {
    cuiAcl,
  },
  data() {
    return {
      MSlicensesmap,
      data: {},
      loading: true,
      licenseColumns: [
        {
          title: 'Name',
          dataIndex: 'skuPartNumber',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Total',
          dataIndex: 'prepaidUnits.enabled',
        },
        {
          title: 'Assigned',
          dataIndex: 'consumedUnits',
        },
        {
          title: 'Available',
          scopedSlots: { customRender: 'availableUnits' },
        },
        {
          title: 'Standard Email License',
          scopedSlots: { customRender: 'standardLicense' },
        },
        {
          title: 'Edit Amount',
          scopedSlots: { customRender: 'editAmount' },
        },
      ],
    }
  },
  async mounted() {
    await this.updateSubscribedSkus()
    firestore.collection('settings').doc('integrations').collection('integrations').doc('Microsoft365').get()
      .then(doc => {
        this.data = doc.data()
        this.data.licenses.forEach(el => {
          el.key = el.skuId
        })
        // order licenses by name
        // this.data.licenses.sort((a, b) => {
        //   const nameA = MSlicensesmap.find(el => el.String_Id === a.skuPartNumber).Product_Display_Name
        //   const nameB = MSlicensesmap.find(el => el.String_Id === b.skuPartNumber).Product_Display_Name
        //   if (nameA < nameB) {
        //     return -1
        //   }
        //   if (nameA > nameB) {
        //     return 1
        //   }
        //   return 0
        // })
      })
    this.loading = false
    console.log(this.loading)
  },
  methods: {
    changeStandardLicense(license) {
      this.data.licenses.forEach(el => {
        if (el.skuPartNumber === license.skuPartNumber) {
          el.standardLicense = true
        } else {
          el.standardLicense = false
        }
      })
      firestore.collection('settings').doc('integrations').collection('integrations').doc('Microsoft365').update({
        licenses: this.data.licenses,
        standardLicense: license.skuId,
      })
    },
    async updateSubscribedSkus() {
      const updateSubscribedSkus = functions.httpsCallable('updateSubscribedSkus')
      await updateSubscribedSkus()
    },
    updateIntegration() {
      firestore.collection('settings').doc('integrations').collection('integrations').doc('Microsoft365').update({
        ...this.data,
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
